html {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  // TEXT
  --text-primary: #333;
  --text-secondary: #999;
  --text-placeholder: #aaa;
  --text-success: #21a355;
  // COLORS
  --color-danger: #f00;
  --color-accent: #1890ff;
  // BG
  --bg-hover: rgba(0, 0, 0, 0.04);
}

.hidden-scroll {
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
}
